<script>
import utilsMixin from '@shared/mixins/utils';
import communityMixin, { postItem } from '@shared/mixins/community';
import BaseAvatar from '@shared/components/BaseAvatar.vue';
import PostItemContextMenu from '@shared/components/Community/PostItemContextMenu.vue';
import CommentsListWrap from '@shared/components/Community/CommentsListWrap.vue';
import UploadedMediaCard from '@shared/components/UploadedMediaCard.vue';

export default {
  mixins: [utilsMixin, communityMixin('post'), postItem],
  components: {
    BaseAvatar,
    PostItemContextMenu,
    CommentsListWrap,
    UploadedMediaCard,
  },
};
</script>

<template>
  <article>
    <header
      class="
        is-flex is-justify-content-space-between
        is-align-items-flex-start mb-4
      "
    >
      <h2 class="title is-4 is-size-5-mobile mb-0 is-custom">
        {{ post.title }}
      </h2>
      <PostItemContextMenu
        class="h-auto"
        :canManageCommunityEntity="canManageCommunityEntity"
        :post="post"
      />
    </header>

    <div v-if="lastCommentAt" class="is-size-8 mb-5">
      <b-tooltip position="is-top">
        <template #content>
          Dernière réponse <br>
          le {{ lastCommentAt | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
        </template>
        Dernière réponse,
        {{ lastCommentAt | momentFromUTC | moment('from') }}
      </b-tooltip>
    </div>

    <div class="card">
      <div class="card-content p-3">
        <div class="media">
          <div class="media-left mr-2">
            <BaseAvatar
              :avatar="post.owner.avatar"
              :displayName="displayName"
            />
          </div>
          <div class="media-content">
            <p class="has-text-weight-bold is-size-6 is-size-8-mobile">
              {{ displayName }}
            </p>
            <p class="subtitle is-9">
              <router-link
                class="has-text-dark"
                :to="{
                  name: 'community_group_post',
                  params: {postUUID: post.uuid},
                }"
              >
                <b-tooltip>
                  <template #content>
                    Publiée <br>
                    le {{ post.published_at | momentFromUTC | moment('DD/MM/YY [à] HH:mm') }}
                  </template>
                  {{ post.published_at | momentFromUTC | moment('from') }}
                </b-tooltip>
              </router-link>
            </p>
          </div>
          <nav class="media-right">
            <span
              v-if="isScheduled"
              class="tag is-black mr-5"
            >
              <span class="is-hidden-mobile">Programmée</span>
              <b-icon class="ml-2 ml-0-mobile" icon="clock" />
            </span>
            <span
              v-if="post.pinned"
              class="tag is-black mr-5"
            >
              <span class="is-hidden-mobile">Épinglée</span>
              <b-icon class="ml-2 ml-0-mobile" icon="thumbtack" />
            </span>
          </nav>
        </div>
        <div class="content break-words" v-html="content" />
        <UploadedMediaCard v-if="post.media.url" :media="post.media" />
      </div>
    </div>

    <CommentsListWrap ref="comments" :post="post" />
  </article>
</template>
