<script>
import { postSingle } from '@shared/mixins/community';
import ListPostDetailedItem from '@shared/components/Community/ListPostDetailedItem.vue';
import CardCommentItem from '@shared/components/Community/CardCommentItem.vue';

export default {
  mixins: [postSingle],
  components: {
    ListPostDetailedItem,
    CardCommentItem,
  },
};
</script>

<template>
  <section>
    <template v-if="comment">
      <h3 class="title is-6">
        Le réponse :
      </h3>
      <CardCommentItem
        class="box"
        :post="post"
        :comment="comment"
        :parent="comment.parent"
        isStandAlone
      />

      <h3 class="title is-6">
        La publication associée :
      </h3>
    </template>
    <ListPostDetailedItem :post="post" />
  </section>
</template>
