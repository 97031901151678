<script>
import { postSingle } from '@shared/mixins/community';
import CardPostItem from '@shared/components/Community/CardPostItem.vue';
import CardCommentItem from '@shared/components/Community/CardCommentItem.vue';

export default {
  mixins: [postSingle],
  components: {
    CardPostItem,
    CardCommentItem,
  },
};
</script>

<template>
  <section class="mw-640 mx-auto">
    <template v-if="comment">
      <h3 class="title is-6">
        Le réponse :
      </h3>
      <CardCommentItem
        class="box"
        :post="post"
        :comment="comment"
        :parent="comment.parent"
        isStandAlone
      />

      <h3 class="title is-6">
        La publication associée :
      </h3>
    </template>
    <CardPostItem :post="post" />
  </section>
</template>
